<template>
  <div class="page_container">
    <section class="row center">
      <div class="pt-35 pb-5 w600 row space-between">
        <h1 class="my-0">View your invoice</h1>
        <router-link to="/invoices" class="row align-end gap-10 mb-2">
          <s-icon color="white" height="20">chevron-left</s-icon>
          <div class="my-0">Back</div>
        </router-link>
      </div>
    </section>
    <hr class="mt-15" />
    <section class="center py-50 col">
      <div class="w600 weight-300">Software license invoice</div>
    </section>

    <section class="w600 mx-auto mb-20">
      <div class="grid" v-for="item in invoice.items" :key="item.idx">
        <div class="label">Name</div>
        <div class="item">{{ item.name }}</div>
        <div class="label">Amount</div>
        <div class="item">{{ item.amount }}</div>
        <div class="label">Price</div>
        <div class="item">{{ formatCurrency(item.price) }}</div>
      </div>
    </section>

    <section class="w600 mx-auto mb-20">
      <div class="grid">
        <div class="label">Total Price</div>
        <div class="item">{{ formatCurrency(invoice.priceTotal) }}</div>
        <div v-if="invoice.notes" class="label">Notes</div>
        <div v-if="invoice.notes" class="item">{{ invoice.notes }}</div>
        <div v-if="invoice.createdAt" class="label">Created At</div>
        <div v-if="invoice.createdAt" class="item">
          {{ format(invoice.createdAt) }}
        </div>
        <div class="label">Invoice Paid</div>
        <div class="item">{{ invoice.isPaid ? "Paid" : "Unpaid" }}</div>
      </div>
    </section>

    <section class="w600 mx-auto mt-20 row gap-20 space-between">
      <s-btn @click.native="generateReport" class="green"
        >Download / print</s-btn
      >
      <router-link to="/invoices" class="green">
        <s-btn class="green">close</s-btn>
      </router-link>
    </section>

    <vue-html2pdf
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="Bank-Details-SecurCom"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <section class="w600 mx-auto mb-20 black--text pt-50">
          <div class="grid" v-for="item in invoice.items" :key="item.idx">
            <div class="label">Name</div>
            <div class="item">{{ item.name }}</div>
            <div class="label">Amount</div>
            <div class="item">{{ item.amount }}</div>
            <div class="label">Price</div>
            <div class="item">{{ formatCurrency(item.price) }}</div>
          </div>
        </section>

        <section class="w600 mx-auto mb-20 black--text pt-50">
          <div class="grid">
            <div class="label">Total Price</div>
            <div class="item">{{ formatCurrency(invoice.priceTotal) }}</div>
            <div v-if="invoice.notes" class="label">Notes</div>
            <div v-if="invoice.notes" class="item">{{ invoice.notes }}</div>
            <div v-if="invoice.createdAt" class="label">Created At</div>
            <div v-if="invoice.createdAt" class="item">
              {{ format(invoice.createdAt) }}
            </div>
            <div class="label">Invoice Paid</div>
            <div class="item">{{ invoice.isPaid ? "Paid" : "Unpaid" }}</div>
          </div>
        </section>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import { mapState } from "vuex";
import VueHtml2pdf from "vue-html2pdf";
import dayjs from "dayjs";
export default {
  components: {
    VueHtml2pdf,
  },
  data() {
    return {};
  },
  methods: {
    formatCurrency(x) {
      x = Number(x).toFixed(2);
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return "$ " + parts.join(".");
    },
    format(val) {
      return dayjs(val).format("DD MMM YY");
    },
    sentense(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getName(id) {
      return this.products.find((el) => el.id == id).name;
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
  computed: {
    ...mapState("invoice", ["invoice"]),
    ...mapState("products", ["products"]),
  },
  watch: {},
  mounted() {
    this.$store.dispatch("products/getProducts");
  },
};
</script>

<style lang="less" scoped>
.label {
  background-color: rgba(0, 0, 0, 0.35);
  padding: 8px;
  border-bottom: 1px solid #42515e;
}

.item {
  display: flex;
  align-items: center;
  padding-left: 8px;
  border-bottom: 1px solid #42515e;
  border-right: 1px solid #42515e;
}

.grid {
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-column-gap: 2px;
  width: 100%;
  transition: 0.2s all ease;
  border-top: 1px solid #42515e;
}
</style>